import "./src/styles/tailwind.css"

import { addAnalytics, sendPageView } from "./src/handle_ga"

export const onClientEntry = () => {
    setTimeout(addAnalytics, 0)
}

export const onRouteUpdate = ({ location }) => {
    sendPageView(location)
}
