import Cookie from "js-cookie"
import siteMetadata from "../config/siteMetadata"
import addMonths from "date-fns/addMonths"

const gaCode = siteMetadata.analytics.ga
const gtmCode = siteMetadata.analytics.tagManager

const accept_cookie = "helene_allow_gtag"
const gaDisable = "ga-disable-" + gaCode

const gaScript = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${gaCode}', {
        anonymize_ip: true
    });

    window.dataLayer.push({'gtm.start':new Date().getTime(),event:'gtm.js'})
`

export async function setCookieChoice(allow: boolean) {
    Cookie.set(accept_cookie, allow ? "true" : "false", {
        expires: addMonths(new Date(), 3),
    })

    // @ts-ignore
    window["allow_cookies"] = allow

    if (!allow) {
        return
    }

   await addAnalytics()
}

export function getCookieChoice() {
    return Cookie.get(accept_cookie) === "true"
}

export function hasCookieChoice() {
    return (
        Cookie.get(gaDisable) === "true" ||
        Cookie.get(accept_cookie) === "true" ||
        Cookie.get(accept_cookie) === "false"
    )
}

export function setGaOptOut() {
    Cookie.remove(accept_cookie)

    // @ts-ignore
    window.gtag = null;

    window.location.reload()
}

function analyticsAllowed() {
    // google opt out
    if (Cookie.get(gaDisable) === "true") {
        // @ts-ignore
        window[gaDisable] = true
        return false
    }

    // check if user accepted cookies
    if (!getCookieChoice()) {
        return false
    }

    // respect dnt
    if (
        navigator.doNotTrack === "1" ||
        parseInt((window as any)?.doNotTrack) === 1 ||
        navigator.doNotTrack === "yes" ||
        // @ts-ignore
        navigator.msDoNotTrack === "1"
    ) {
        return false
    }

    return true
}

function loadScript(id: string, src: string): Promise<void> {
    if (document.getElementById(id)) {
        return Promise.resolve()
    }

    const script = document.createElement("script")
    script.src = src
    script.async = true

    return new Promise(res => {
        const fn = () => {
            res()

            script.removeEventListener("load", fn)
        }

        script.addEventListener("load", fn)
        document.body.append(script)
    })
}

export async function addAnalytics() {
    if (!analyticsAllowed()) {
        return
    }

    const promises: Promise<void>[] = []

    if (gaCode) {
        promises.push(
            loadScript(
                "gatsby-plugin-google-gtag",
                `https://www.googletagmanager.com/gtag/js?id=${gaCode}`
            )
        )
    }

    if (gtmCode) {
        promises.push(
            loadScript(
                "gatsby-plugin-google-gtag",
                `https://www.googletagmanager.com/gtm/js?id=${gtmCode}`
            )
        )
    }

    const wait = Promise.all(promises)

    if (document.getElementById("ga-script")) {
        await wait

        return
    }

    const script = document.createElement("script")
    script.id = "ga-script"
    script.innerHTML = gaScript

    document.body.append(script)

    setTimeout(() => {
        sendPageView(window.location)
    }, 2000)

    await wait
}

export const ga_event = (
    eventName: Gtag.EventNames | string,
    eventParams?: Gtag.EventParams | Gtag.CustomParams
) => {
    if (!window.gtag) {
        return
    }

    window.gtag("event", eventName, eventParams)
}

export const sendPageView = (location: Location) => {
    if (!analyticsAllowed()) {
        return
    }

    const run = () => {
        const pagePath = location
            ? location.pathname + location.search + location.hash
            : undefined

        ga_event("page_view", {
            page_path: pagePath,
        })
    }

    if ("requestAnimationFrame" in window) {
        requestAnimationFrame(() => {
            requestAnimationFrame(run)
        })
    } else {
        // simulate 2 rAF calls
        setTimeout(run, 32)
    }
}
